/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.divider {
  padding-top: 6vh;
}

.card1 {
  color: #fff;
  text-align: center;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  box-shadow: 0 4px 8px 0 #91d5ff91, 0 6px 20px 0 #91d5ff91;
  background-color: #91d5ff;
  /* max-width: 480px; */
}
.card2 {
  text-align: center;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 #69c1ff8a, 0 6px 20px 0 #69c1ff8a;
  background-color: #69c0ff;
  /* max-width: 480px; */

  /* margin: 0.1in 0.1in 0.1in 0.1in; */
}
.card3 {
  text-align: center;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 #40a9ff57, 0 6px 20px 0 #40a9ff57;
  background-color: #40a9ff;
  /* max-width: 480px; */
}

.icon {
  color: #fff;
}
